'use client';

import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { Locale } from '@/app/i18n/locale';
import Container from '@/components/Container';
import MenuWrapper from '@/components/MenuWrapper';
import ProgressBar from '@/components/ProgressBar';

import styles from './styles.module.scss';

const Header = ({ lng }: { lng: Locale }) => {
  const pathname = usePathname();

  const isBreathPage = useMemo(() => {
    return pathname === '/breath';
  }, [pathname]);

  if (isBreathPage) {
    return null;
  }

  return (
    <nav className={styles.nav}>
      <ProgressBar />
      <Container className={styles.menu}>
        <MenuWrapper lng={lng} />
      </Container>
    </nav>
  );
};

export default Header;
