'use client';

import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { useState, useEffect } from 'react';

import { Locale } from '@/app/i18n/locale';
import globe from 'public/icons/earth-outline.svg';

import styles from './styles.module.scss';

const LocaleSwitcher = () => {
  const pathname = usePathname();
  const router = useRouter();
  const [isShown, setIsShown] = useState(false);

  const split = pathname.split('/');

  const lng = split[1] as Locale;

  const switchLanguage = (switcherLang: string) => {
    if (pathname) {
      const splittedPathname = pathname.slice(4);
      router.push(`/${switcherLang}/${splittedPathname}`);
    }
  };

  let lngString = [''];
  switch (lng as 'pl' | 'en' | 'cz') {
    case 'en':
      lngString = ['PL', 'CZ'];
      break;
    case 'cz':
      lngString = ['PL', 'EN'];
      break;
    default:
      lngString = ['CZ', 'EN'];
      break;
  }

  return (
    <div className={styles.wrapper} onMouseLeave={() => setIsShown(false)}>
      <button
        className={styles.languageSwitcher}
        onClick={() => setIsShown(prev => !prev)}
      >
        <Image
          id="localeMenu"
          src={globe}
          alt="Globe icon"
          width={24}
          height={24}
        />
      </button>
      {isShown && (
        <div className={styles.dropdownMenu}>
          <ul className={styles.dropdownList}>
            {lngString.map((el, id) => {
              return (
                <li
                  key={el + id}
                  onClick={() => switchLanguage(el.toLowerCase())}
                  className={styles.dropdownItem}
                >
                  {el}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LocaleSwitcher;
