import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/cart-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/right-arrw-link.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/homepage/VideoBannerSection/VideoBannerSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Container/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/blocks/homepage/GreyBackgroundInfoSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Button/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/blocks/homepage/TileWithImageSection/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PageIntro/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/blocks/homepage/TitleSection/styles.module.scss");
