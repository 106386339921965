'use client';

import { usePathname } from 'next/navigation';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Locale } from '@/app/i18n/locale';
import Container from '@/components/Container';
import FooterLinks from '@/components/FooterLinks';
import Newsletter from '@/components/Newsletter';
import SocialMediaList from '@/components/SocialMediaList';
import { NavigationContext } from '@/contexts/NavigationContext';
import { FooterData } from '@/utils/strapi/types/api';

import styles from './styles.module.scss';

const Footer = ({ lng, data }: { lng: Locale; data: FooterData }) => {
  const navigationData = useContext(NavigationContext);
  const [isNotVisible, setIsNotVisible] = useState(false);
  const pathname = usePathname();

  const isBreathPage = useMemo(() => {
    return pathname === '/en/breath' || pathname === '/pl/breath';
  }, [pathname]);

  useEffect(() => {
    if (pathname?.includes('/koszyk') || pathname?.includes('/zamowienie')) {
      setIsNotVisible(true);
    } else {
      setIsNotVisible(false);
    }
  }, [pathname]);

  const { SocialMedia } = data.data?.attributes;

  if (navigationData) {
    const { footer } = navigationData;
    return (
      <>
        <>
          {!isBreathPage && !isNotVisible && <Newsletter lng={lng} />}
          <footer className={styles.footer}>
            <Container className={styles.container}>
              <div className={styles.wrapper}>
                <div id="informacje" className={styles.navigation}>
                  {footer.items.data.map(category => (
                    <FooterLinks
                      key={category.id}
                      label={category.attributes.title}
                      data={category.attributes.children.data}
                      lng={lng}
                    />
                  ))}
                </div>
                <div className={styles.bottom}>
                  <p className={styles.copy}>
                    Designed and engineered in Poland
                  </p>
                </div>
              </div>
              <SocialMediaList data={SocialMedia} />
            </Container>
          </footer>
        </>
      </>
    );
  }

  return <></>;
};

export default Footer;
