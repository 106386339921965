import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import cartIcon from 'public/icons/cart-icon.svg';
import rightLinkArrow from 'public/icons/right-arrw-link.svg';

import styles from './styles.module.scss';
import { Props } from './types';

const Button = ({
  href,
  onClick,
  variant = 'primary',
  isActive = false,
  type,
  disabled = false,
  children,
  className,
  isNoArrow,
  isPlus,
  isMinus,
  isCart,
  isCapitalize,
  isLinkArrow,
  ref,
}: Props) => {
  const btnClass = classNames(
    styles.btn,
    className,
    disabled && styles.disabled,
    variant === 'icon' && styles.iconLink,
    variant === 'navIcons' && styles.iconButton,
    disabled && variant === 'navIcons' && styles.disabledIcon,
    isActive && styles.active,
    isCapitalize && styles.capitalize,
  );

  if (onClick || type) {
    return (
      <button
        onClick={onClick}
        className={btnClass}
        type={type}
        disabled={disabled}
        ref={ref}
      >
        {children}
        {isMinus && (
          <MinusCircleIcon
            className={[
              styles.iconQuantity,
              disabled && styles.disabledBtn,
            ].join(' ')}
          />
        )}
        {isPlus && (
          <PlusCircleIcon
            className={[
              styles.iconQuantity,
              disabled && styles.disabledBtn,
            ].join(' ')}
          />
        )}
        {isCart && <Image src={cartIcon} alt="Ikona" height={20} width={20} />}
      </button>
    );
  }

  return (
    <Link href={href || '#'} className={btnClass}>
      {children}
      {!isNoArrow && <ArrowRightIcon className={styles.icon} />}
      {isLinkArrow && (
        <Image
          src={rightLinkArrow}
          alt="Ikona"
          height={15}
          width={15}
          className={styles.backIcon}
        />
      )}
    </Link>
  );
};

export default Button;
