'use client';

import { Dialog } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { createContext, useState } from 'react';

import { ToastContextType } from '@/utils/types/ToastContextType';

const ToastState = createContext<ToastContextType>({
  openDialog: () => null,
});

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState('');
  const [opened, { open, close }] = useDisclosure(false);

  const handleProvider = (title: string) => {
    setTitle(title);
    open();
  };

  return (
    <ToastState.Provider
      value={{
        openDialog: handleProvider,
      }}
    >
      {children}
      <Dialog
        opened={opened}
        withCloseButton
        onClose={close}
        size="xl"
        radius="sm"
        position={{ bottom: 20, left: 20 }}
        styles={{
          root: {
            padding: '2rem !important',
            fontSize: '1.35rem',
            width: '110%',
          },
          closeButton: {
            width: '2rem !important',
            height: '2rem !important',
          },
        }}
      >
        {title}
      </Dialog>
    </ToastState.Provider>
  );
};

export default ToastState;
