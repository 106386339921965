'use client';

import { useContext, useEffect, useState } from 'react';

import { Locale } from '@/app/i18n/locale';
import Menu from '@/components/Menu';
import MenuMobile from '@/components/MenuMobile';
import ScreenWidthContext from '@/contexts/ScreenWidthContext';

const MenuWrapper = ({ lng }: { lng: Locale }) => {
  const [isMobile, setIsMobile] = useState(false);
  const { screenWidth } = useContext(ScreenWidthContext);

  useEffect(() => {
    screenWidth && setIsMobile(screenWidth <= 999);
  }, [screenWidth]);

  return (
    <>
      {isMobile ? (
        <MenuMobile isMobile={isMobile} lng={lng} />
      ) : (
        <Menu isMobile={isMobile} lng={lng} />
      )}
    </>
  );
};

export default MenuWrapper;
