import Link from 'next/link';

import styles from './styles.module.scss';
import { Props } from './types';

const FooterLinks = ({ label, data, lng }: Props) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.label}>{label}</h3>
      <div className={styles.linksWrapper}>
        {data.map(item => {
          const { title, url } = item.attributes;
          return (
            <Link
              key={item.id}
              href={`/${lng}${url}` ?? ''}
              className={styles.link}
              lang={lng}
            >
              {title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default FooterLinks;
