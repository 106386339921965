'use client';

import Link from 'next/link';
import { useContext } from 'react';

import { Locale } from '@/app/i18n/locale';
import { NavigationContext } from '@/contexts/NavigationContext';

import styles from './styles.module.scss';

const Navigation = ({ lng }: { lng: Locale }) => {
  const navigationData = useContext(NavigationContext);

  if (navigationData) {
    const { header } = navigationData;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.navigationList}>
            {header.items.data.map(item => {
              const {
                attributes: { title, url },
              } = item;
              return (
                <div key={item.id} className={styles.navItem}>
                  <Link href={`/${lng}${url}` ?? ''} className={styles.link}>
                    {title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

export default Navigation;
