'use client';

import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';

import Button from '@/components/Button';

import styles from './styles.module.scss';

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  useEffect(() => {
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <section>
      <div className={styles.container}>
        <h2>Coś poszło nie tak!</h2>
        <Button onClick={() => reset()}>Spróbuj ponownie</Button>
        <Link href="/" className={styles.link}>
          Wróć na stronę główną
        </Link>
      </div>
    </section>
  );
};

export default Error;
