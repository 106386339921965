import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';
import { Props } from './types';

const PartialBorderInput = ({
  type = 'text',
  value,
  onChange,
  label,
  placeholder,
  id,
  name,
  isError = false,
  errorMsg,
  className,
  disabled,
}: Props) => {
  const inputClass = classNames(styles.inputWrapper, className);

  return (
    <fieldset className={inputClass}>
      <legend className={styles.label}>{label}</legend>
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.input}
        id={id}
        disabled={disabled}
      />
      {isError && <p className={styles.error}>{errorMsg}</p>}
    </fieldset>
  );
};

export default PartialBorderInput;
