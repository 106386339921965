'use client';

import React, { createContext, useState, useEffect } from 'react';

import { ScreenWidthContextType } from '@/utils/types/ScreenWidthContextType';

const ScreenWidthState = createContext<ScreenWidthContextType>({
  screenWidth: undefined,
  isTablet: false,
  isMobile: false,
  isSmallScreen: false,
});

export const ScreenWidthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [screenWidth, setScreenWidth] = useState<number>();
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const toogleScreenWidthState = () => {
      setScreenWidth(window.innerWidth);
      setIsTablet(window.innerWidth <= 1100);
      setIsMobile(window.innerWidth <= 900);
      setIsSmallScreen(window.innerWidth <= 600);
    };

    toogleScreenWidthState();

    window.addEventListener('resize', () => toogleScreenWidthState());

    return () => {
      window.removeEventListener('resize', () => toogleScreenWidthState());
    };
  }, []);

  return (
    <ScreenWidthState.Provider
      value={{
        screenWidth,
        isTablet,
        isMobile,
        isSmallScreen,
      }}
    >
      {children}
    </ScreenWidthState.Provider>
  );
};

export default ScreenWidthState;
