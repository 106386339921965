import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Hamburger from '@/components/Hamburger';
import LocaleSwitcher from '@/components/LocaleSwitcher';
import Navigation from '@/components/Navigation';
import { MobileProps } from '@/components/Navigation/types';
import CartContext from '@/contexts/CartContext';
import cartIcon from 'public/icons/shopping_bag.svg';
import logo from 'public/images/UNITRA-logo.svg';

import styles from './styles.module.scss';

const MenuMobile = ({ isMobile, lng }: MobileProps) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [qty, setQty] = useState(0);
  const { cart } = useContext(CartContext);
  const pathname = usePathname();

  const toogleMobileMenu = () => {
    setIsMobileMenuOpened(prevIsMobileMenuOpened => !prevIsMobileMenuOpened);
  };

  useEffect(() => {
    setIsMobileMenuOpened(false);
  }, [pathname]);

  useEffect(() => {
    if (cart) {
      const productsQts = cart.items.map(el => el.quantity);
      const sum = productsQts.reduce((a, b) => a + b, 0);
      setQty(sum);
    } else {
      setQty(0);
    }
  }, [cart]);

  return (
    <>
      <div className={styles.menuWrapper}>
        <Link href={`/${lng}`} className={styles.logoWrapper}>
          <Image src={logo} alt="Logo UNITRA" width={122} height={45} />
        </Link>
        <div className={styles.iconsWrapper}>
          {isMobile && (
            <>
              <div className={styles.localeCartContainer}>
                <LocaleSwitcher />
                {lng === 'cs' || (
                  <Button
                    className={styles.cartBtn}
                    variant="navIcons"
                    href={`/${lng}/koszyk`}
                    isNoArrow
                  >
                    <>
                      {qty > 0 && (
                        <div className={styles.itemCounter}>{qty}</div>
                      )}
                      <Image
                        src={cartIcon}
                        alt="Cart icon"
                        height={26}
                        width={26}
                      />
                    </>
                  </Button>
                )}
              </div>
            </>
          )}
          <Hamburger
            toogleMenu={toogleMobileMenu}
            isMobileMenuOpened={isMobileMenuOpened}
          />
        </div>
      </div>
      <div
        className={[styles.menu, isMobileMenuOpened && styles.opened].join(' ')}
      >
        {isMobileMenuOpened && <Navigation lng={lng} />}
      </div>
    </>
  );
};

export default MenuMobile;
