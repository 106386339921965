'use client';

import { createContext } from 'react';

import { Locale } from '@/app/i18n/locale';
import {
  StrapiMenuAttributes,
  StrapiMenuData,
} from '@/utils/strapi/types/strapi';

import { NavigationContextType } from './types';

export const NavigationContext = createContext<NavigationContextType | null>(
  null,
);

export const NavigationProvider = ({
  children,
  data,
  lng,
}: {
  children: React.ReactNode;
  data: StrapiMenuData;
  lng: Locale;
}) => {
  const HEADER_LIMIT = 5;

  const getMenus = (
    menus: StrapiMenuData,
  ): {
    header: StrapiMenuAttributes | undefined;
    footer: StrapiMenuAttributes | undefined;
  } => {
    const titles = {
      pl: { header: 'Naglowek', footer: 'Stopka' },
      cs: { header: 'Nadpis', footer: 'Stopka-cs' },
      en: { header: 'Header', footer: 'Footer' },
      default: { header: 'Header', footer: 'Footer' },
    };

    const { header: headerTitle, footer: footerTitle } =
      titles[lng] || titles.default;

    const headerItem = menus.data.find(
      item => item.attributes.title === headerTitle,
    );
    const footerItem = menus.data.find(
      item => item.attributes.title === footerTitle,
    );

    return {
      header: headerItem?.attributes,
      footer: footerItem?.attributes,
    };
  };

  const { header, footer } = getMenus(data);

  if (!header || !footer) {
    throw new Error('Header or footer not found');
  }

  const slicedHeader = {
    ...header,
    items: {
      ...header.items,
      data: header.items.data.slice(0, HEADER_LIMIT),
    },
  };

  return (
    <NavigationContext.Provider value={{ header: slicedHeader, footer }}>
      {children}
    </NavigationContext.Provider>
  );
};
