'use client';

import React, { createContext, useState } from 'react';

const EmailContext = createContext({
  email: '',
  updateEmail: (newEmail: string) => {},
});

const EmailProvider = ({ children }: { children: React.ReactNode }) => {
  const [email, setEmail] = useState('');

  const updateEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  return (
    <EmailContext.Provider value={{ email, updateEmail }}>
      {children}
    </EmailContext.Provider>
  );
};

export { EmailProvider, EmailContext };
