import { TFunction } from 'i18next';
import * as yup from 'yup';

export const newsletterFormValidationSchema = (
  t: TFunction<string | readonly string[], undefined>,
) => {
  return yup.object({
    email: yup
      .string()
      .email(t('validation.invalid', { ns: 'newsletter' }))
      .required(t('validation.required', { ns: 'newsletter' })),
  });
};

export const addressValidationSchema = (
  t: TFunction<string | readonly string[], undefined>,
) => {
  return yup.object({
    first_name: yup.string().required(t('validation.firstName')),
    last_name: yup.string().required(t('validation.lastName')),
    company: yup.string().optional(),
    address_1: yup.string().required(t('validation.address1')),
    address_2: yup.string().required(t('validation.address2')),
    city: yup.string().required(t('validation.city')),
    postal_code: yup.string().required(t('validation.postalCode')),
    phone: yup.string().required(t('validation.phone')),
  });
};

export const checkoutFormValidationSchema = (
  t: TFunction<string | readonly string[], undefined>,
) => {
  return yup.object({
    email: yup
      .string()
      .email(t('validation.email.invalid'))
      .required(t('validation.email.required')),
    shipping_address: addressValidationSchema(t),
  });
};
