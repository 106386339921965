import { Checkbox } from '@mantine/core';
import classNames from 'classnames';

import { Props } from './types';

const FormCheckbox = ({
  label,
  isRequired = false,
  checked,
  onChange,
}: Props) => {
  const checkboxStyles = classNames(isRequired && 'requiredCheckbox');

  return (
    <Checkbox
      label={label}
      size="lg"
      required={isRequired}
      className={checkboxStyles}
      onChange={onChange}
      aria-checked={checked}
    />
  );
};

export default FormCheckbox;
