import cn from 'classnames';
import { Oval } from 'react-loader-spinner';

import styles from './styles.module.scss';
import { Props } from './types';

const Loader = ({ isLoaderButton }: Props) => {
  return (
    <Oval
      height={isLoaderButton ? 22 : 70}
      width={isLoaderButton ? 22 : 70}
      color={styles.primaryColorLight}
      wrapperStyle={{}}
      wrapperClass={cn(styles.spinner, {
        [styles.noPadding]: isLoaderButton,
      })}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={styles.primaryColor}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default Loader;
