import styles from './styles.module.scss';
import { Props } from './types';

const Hamburger = ({ toogleMenu, isMobileMenuOpened }: Props) => {
  return (
    <div className={styles.wrapper} onClick={toogleMenu}>
      <div
        className={[styles.menuIcon, isMobileMenuOpened && styles.cross].join(
          ' ',
        )}
      >
        <label>
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path className={styles.line1} d="M0 70l28-28c2-2 2-2 7-2h64" />
            <path className={styles.line2} d="M0 50h99" />
            <path className={styles.line3} d="M0 30l28 28c2 2 2 2 7 2h64" />
          </svg>
        </label>
      </div>
    </div>
  );
};

export default Hamburger;
