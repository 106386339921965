'use client';

import {
  useCart,
  useGetCart,
  useCreateLineItem,
  useDeleteLineItem,
  useUpdateLineItem,
  useRegions,
} from 'medusa-react';
import React, { useEffect, useState, createContext } from 'react';
import toast from 'react-hot-toast';

import { useTranslation } from '@/app/i18n/client';
import { Locale } from '@/app/i18n/locale';
import { CartContextType } from '@/utils/types/CartContextType';

const CartContext = createContext<CartContextType>({
  cart: undefined,
  regions: undefined,
  addItemToCart: async (variant_id, quantity) => {},
  handleDeleteItem: async lineId => {},
  updateItem: async (lineId, quantity) => {},
  deleteCart: () => {},
  refetchCart: async () => {},
});

const CART_KEY = 'cart_id';
export const APPROVED_KEY = 'is_approved';

export const CartProvider = ({
  lng,
  children,
}: {
  lng: Locale;
  children: React.ReactNode;
}) => {
  const [id, setId] = useState<string | null>(null);
  const { cart, refetch: refetchCart } = useGetCart(id || '', {
    enabled: !!id,
  });
  const { createCart } = useCart();
  const { regions } = useRegions();
  const { t } = useTranslation(lng, 'cart');

  const { mutateAsync: createLineItem } = useCreateLineItem(id || '');
  const { mutateAsync: deleteLineItem } = useDeleteLineItem(id || '');
  const { mutateAsync: updateLineItem } = useUpdateLineItem(id || '');

  const handleDeleteItem = async (lineId: string) => {
    await deleteLineItem({
      lineId,
    });
    refetchCart();
  };

  const addItemToCart = async (variant_id: string, quantity: number) => {
    await createLineItem({
      variant_id,
      quantity,
    });
    refetchCart();
    toast.success(t('toastAdded'));
  };

  const updateItem = async (lineId: string, quantity: number) => {
    await updateLineItem({
      lineId,
      quantity,
    });
    refetchCart();
  };

  const deleteCart = () => {
    localStorage.removeItem(CART_KEY);
    localStorage.removeItem(APPROVED_KEY);
    setId(null);
  };

  useEffect(() => {
    const lsId = localStorage.getItem(CART_KEY);
    if (lsId) {
      setId(lsId);
    }

    if (!lsId && regions?.length) {
      const chosenRegion = regions[0];
      createCart.mutate(
        {
          region_id: chosenRegion.id,
          country_code: 'pl',
        },
        {
          onSuccess: async ({ cart }) => {
            localStorage.setItem(CART_KEY, cart.id);
            setId(cart.id);
          },
        },
      );
    }
  }, [regions]);

  useEffect(() => {
    if (id) {
      refetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CartContext.Provider
      value={{
        cart,
        regions,
        addItemToCart,
        handleDeleteItem,
        updateItem,
        deleteCart,
        refetchCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
