'use client';

import { Form, Formik, FormikState } from 'formik';
import Image from 'next/image';
import { useContext, useState, useEffect } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { Locale } from '@/app/i18n/locale';
import Button from '@/components/Button';
import Container from '@/components/Container';
import FormCheckbox from '@/components/FormCheckbox';
import Loader from '@/components/Loader';
import PartialBorderInput from '@/components/PartialBorderInput';
import ToastContext from '@/contexts/ToastDialogContext';
import { newsletterFormValidationSchema } from '@/utils/validation';
import unitraCover from 'public/images/unitra-schema.png';

import styles from './styles.module.scss';
import { Values } from './types';

const Newsletter = ({ lng }: { lng: Locale }) => {
  const { openDialog } = useContext(ToastContext);
  const [isRulesChecked, setIsRulesChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const { t } = useTranslation(lng, 'newsletter');

  const initialValues: Values = {
    email: '',
  };

  const onSubmit = async (
    values: Values,
    {
      resetForm,
    }: { resetForm: (nextState?: Partial<FormikState<Values>>) => void },
  ) => {
    const { email } = values;
    setIsLoading(true);

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      setIsLoading(false);

      if (response.status === 201) {
        openDialog(t('dialog.success'));
        resetForm();
      }

      if (response.status === 500 || response.status === 400) {
        openDialog(t('dialog.duplicate'));
      }
    } catch (err) {
      setIsLoading(false);
      openDialog(t('dialog.error'));
    }
  };
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) return null;

  return (
    <div className={styles.containerWrapper}>
      <Container>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.top}>
              <h2 className={styles.title}>{t('title')}</h2>
              <p className={styles.text}>{t('description')}</p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={() => newsletterFormValidationSchema(t)}
              onSubmit={onSubmit}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form className={styles.bottom}>
                  <PartialBorderInput
                    id="email"
                    type="email"
                    name="email"
                    label={t('formLabel')}
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email && (
                    <p className={styles.error}>{errors.email}</p>
                  )}
                  <div className="newsletterCheckboxWrapper">
                    <FormCheckbox
                      label={t('formConsent')}
                      isRequired
                      checked={isRulesChecked}
                      onChange={() => setIsRulesChecked(prev => !prev)}
                    />
                  </div>
                  <Button
                    isNoArrow
                    className={styles.btn}
                    type="submit"
                    disabled={!isRulesChecked}
                  >
                    {!isLoading ? t('submitButton') : <Loader isLoaderButton />}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          <div className={styles.imageWrapper}>
            <Image
              src={unitraCover}
              alt="Rysunek UNITRA Artboard"
              height={395}
              width={561}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Newsletter;
