import Image from 'next/image';
import Link from 'next/link';

import { SocialMedia } from '@/utils/strapi/types/api';

import styles from './styles.module.scss';

export const SocialMediaList = ({ data }: { data: SocialMedia[] }) => {
  return (
    <ul className={styles.list}>
      {data.map(social => {
        return (
          <li key={social.id} className={styles.item}>
            <a
              href={social.Link}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={social.Ikona.data.attributes.url}
                alt={`${social.Tekst} icon`}
                width={24}
                height={24}
              />
              <p className={styles.text}>{social.Tekst}</p>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
