'use client';

import { QueryClient } from '@tanstack/react-query';
import {
  MedusaProvider,
  CartProvider,
  SessionCartProvider,
} from 'medusa-react';

type Props = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();
const baseUrl =
  process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL || 'http://localhost:9000';

const MedusaProviderWrapper = ({ children }: Props) => {
  return (
    <>
      <MedusaProvider
        queryClientProviderProps={{ client: queryClient }}
        baseUrl={baseUrl}
      >
        <SessionCartProvider>
          <CartProvider>{children}</CartProvider>
        </SessionCartProvider>
      </MedusaProvider>
    </>
  );
};

export default MedusaProviderWrapper;
