'use client';

import { useContext } from 'react';

import ScreenWidthState from '@/contexts/ScreenWidthContext';

import styles from './styles.module.scss';
import { Props } from './types';

const VideoBannerSection = ({ VideoBanner, MobileVideo }: Props) => {
  const { isMobile } = useContext(ScreenWidthState);

  const ensureHttpsUrl = (url: string) => {
    if (url && !url.startsWith('https://' || 'http://')) {
      return `https://${url}`;
    }
    return url;
  };

  const videoBannerUrl = ensureHttpsUrl(VideoBanner.data.attributes.url);
  const mobileVideoUrl = ensureHttpsUrl(MobileVideo.data?.attributes.url);

  return (
    <div className={styles.wrapper}>
      {isMobile && mobileVideoUrl ? (
        <video
          loop
          autoPlay
          muted
          playsInline
          src={mobileVideoUrl}
          className={styles.video}
        />
      ) : (
        <video
          loop
          autoPlay
          muted
          playsInline
          src={videoBannerUrl}
          className={styles.video}
        />
      )}
    </div>
  );
};

export default VideoBannerSection;
